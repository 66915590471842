import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "menu-item px-3" }
const _hoisted_2 = { class: "card card-flush" }
const _hoisted_3 = {
  key: 0,
  class: "card-body pt-0"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "menu-item px-3" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuickAction = _resolveComponent("QuickAction")!
  const _component_KtDatatable = _resolveComponent("KtDatatable")!
  const _component_Entities = _resolveComponent("Entities")!
  const _component_TemplateForm = _resolveComponent("TemplateForm")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Entities, {
      "enable-filter": false,
      "view-card": false,
      "view-table": true,
      label: "Templates",
      items: _ctx.templatePage.total,
      "display-view": false
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_QuickAction, { size: "w-150px" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("a", {
                href: "javascript:void(0);",
                class: "menu-link px-3",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addTemplate && _ctx.addTemplate(...args)))
              }, "New Template")
            ])
          ]),
          _: 1
        })
      ]),
      table: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.templatePage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_KtDatatable, {
                  "table-data": _ctx.templatePage.data,
                  "table-header": _ctx.tableHeader,
                  "enable-items-per-page-dropdown": true,
                  "current-page": _ctx.filter.paginationPage,
                  "rows-per-page": _ctx.filter.paginationSize,
                  onItemsPerPageChange: _ctx.handleUpdateSize,
                  onCurrentChange: _ctx.handleUpdatePage,
                  total: _ctx.templatePage.total
                }, {
                  "cell-name": _withCtx(({ row: item }) => [
                    _createElementVNode("a", {
                      href: "javascript:void(0);",
                      onClick: ($event: any) => (_ctx.loadData(item.id, 'TEMPLATE'))
                    }, _toDisplayString(item.name), 9, _hoisted_4)
                  ]),
                  "cell-type": _withCtx(({ row: item }) => [
                    _createTextVNode(_toDisplayString(item.type.name), 1)
                  ]),
                  "cell-subject": _withCtx(({ row: item }) => [
                    _createTextVNode(_toDisplayString(item.subject), 1)
                  ]),
                  "cell-actions": _withCtx(({row: item}) => [
                    _createVNode(_component_QuickAction, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("a", {
                            href: "javascript:void(0);",
                            class: "menu-link px-3",
                            "data-bs-toggle": "modal",
                            "data-bs-target": "#templateModal",
                            onClick: ($event: any) => (_ctx.editTemplate(item))
                          }, "Edit ", 8, _hoisted_6),
                          _createElementVNode("a", {
                            href: "javascript:void(0);",
                            class: "menu-link px-3 text-danger",
                            onClick: ($event: any) => (_ctx.deleteTemplate(item))
                          }, "Delete", 8, _hoisted_7)
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }, 8, ["table-data", "table-header", "current-page", "rows-per-page", "onItemsPerPageChange", "onCurrentChange", "total"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["items"]),
    _createVNode(_component_BaseModal, {
      id: "templateModal",
      name: "Template",
      ref: "refTemplateModal"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_TemplateForm, {
          templateId: _ctx.state.templateId,
          template: _ctx.state.template_,
          onTemplateSaved: _ctx.onTemplateSaved
        }, null, 8, ["templateId", "template", "onTemplateSaved"])
      ]),
      _: 1
    }, 512)
  ], 64))
}