
import { computed, defineComponent, reactive, ref } from "vue";
import { LoadFilterObjects, LoadPanel } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import Entities from "@/components/base/common/Entities.vue";
import store from "@/store";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import TemplateForm from "@/views/template/TemplateForm.vue";
import Swal from "sweetalert2";
import TemplateService from "@/core/services/TemplateService";

export default defineComponent({
  name: "Templates",
  components: { TemplateForm, BaseModal, QuickAction, KtDatatable, Entities},
  props: {
    id: {type: String}
  },
  setup(props) {
    const filterObject = ref({companyId: props.id})
    const templatePage = computed(() => store.state.TemplateModule.templateCompanyPage)
    const state = reactive({
      templateId: '',
      template_: {type: 'NOTE', name: '', subject: '', body: '', companyId: props.id, shared: false}
    })
    const template = computed(() => store.state.TemplateModule.template);
    const tableHeader = ref([
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Type",
        key: "type",
        sortable: true,
      },
      {
        name: "Subject",
        key: "subject",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: false,
      }
    ]);

    return {
      ...LoadFilterObjects(Actions.LOAD_COMPANY_TEMPLATES, filterObject.value, ['company']),
      templatePage,
      tableHeader,
      template,
      state,
      ...LoadPanel()
    }
  },
  methods: {

    onTemplateSaved() {
      const baseModal = this.$refs.refTemplateModal as typeof BaseModal
      baseModal.hideBaseModal()
      this.paginationLoad()
    },

    addTemplate() {
      this.state.templateId = '';
      this.state.template_.type = 'NOTE'
      this.state.template_.name = ''
      this.state.template_.subject = ''
      this.state.template_.body = ''
      this.state.template_.shared = false
      const baseModal = this.$refs.refTemplateModal as typeof BaseModal
      baseModal.showBaseModal()
    },

    editTemplate(temp) {
      this.state.templateId = temp.id;
      this.state.template_.type = temp.type.code;
      this.state.template_.name = temp.name;
      this.state.template_.subject = temp.subject;
      this.state.template_.body = temp.body;
    },

    deleteTemplate(template) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await TemplateService.delete(template.id).then(() => {
            this.paginationLoad();
          })
        }
      })
    }
  }
})
